import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ConsultantTalentFilterData, ApplicationData, ConsultantTalentProfileData, ConsultantTalentEventHistoryData, ProfessionFieldPreferenceData, PagedData, ConsultantTalentPositionData, ConsultantMinimalApplicationData, ConsultantTalentData, ConsultantTalentPositionPreviewData, ConsultantAssessTalentData, ConsultantTalentMinimalData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantTalentController
 */

@Injectable()
@ResourceParams({})
export class ConsultantTalentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/cv',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _downloadCv: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    downloadCv(id: number): Promise<Blob> {
        if (!this._downloadCv) throw new Error("resource has not been properly initialized")
        return this._downloadCv(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/history',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventHistoryOfTalent: IResourceMethodObservableStrict<null, null, {id: number}, ConsultantTalentEventHistoryData[]> | undefined
    getEventHistoryOfTalent(id: number): Promise<ConsultantTalentEventHistoryData[]> {
        if (!this._getEventHistoryOfTalent) throw new Error("resource has not been properly initialized")
        return this._getEventHistoryOfTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/applications/markable',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMarkableAsHiredTalentApplications: IResourceMethodObservableStrict<null, null, {id: number}, ConsultantMinimalApplicationData[]> | undefined
    getMarkableAsHiredTalentApplications(id: number): Promise<ConsultantMinimalApplicationData[]> {
        if (!this._getMarkableAsHiredTalentApplications) throw new Error("resource has not been properly initialized")
        return this._getMarkableAsHiredTalentApplications(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/minimal',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMinimalTalent: IResourceMethodObservableStrict<null, null, {id: number}, ConsultantTalentMinimalData> | undefined
    getMinimalTalent(id: number): Promise<ConsultantTalentMinimalData> {
        if (!this._getMinimalTalent) throw new Error("resource has not been properly initialized")
        return this._getMinimalTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNewestTalentsOfConsultant: IResourceMethodObservableStrict<null, null, null, number[]> | undefined
    getNewestTalentsOfConsultant(): Promise<number[]> {
        if (!this._getNewestTalentsOfConsultant) throw new Error("resource has not been properly initialized")
        return this._getNewestTalentsOfConsultant(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/professionFieldPreferences',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRelevantProfessionFieldPreferencesForTalent: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionFieldPreferenceData[]> | undefined
    getRelevantProfessionFieldPreferencesForTalent(id: number): Promise<ProfessionFieldPreferenceData[]> {
        if (!this._getRelevantProfessionFieldPreferencesForTalent) throw new Error("resource has not been properly initialized")
        return this._getRelevantProfessionFieldPreferencesForTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalent: IResourceMethodObservableStrict<null, null, {id: number}, ConsultantTalentProfileData> | undefined
    getTalent(id: number): Promise<ConsultantTalentProfileData> {
        if (!this._getTalent) throw new Error("resource has not been properly initialized")
        return this._getTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentApplications: IResourceMethodObservableStrict<null, {page?: number, pageSize?: number}, {id: number}, PagedData<ApplicationData>> | undefined
    getTalentApplications(id: number, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<ApplicationData>> {
        if (!this._getTalentApplications) throw new Error("resource has not been properly initialized")
        return this._getTalentApplications(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/applications/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentApplicationsCount: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<number>> | undefined
    getTalentApplicationsCount(id: number): Promise<JsonWrappedValue<number>> {
        if (!this._getTalentApplicationsCount) throw new Error("resource has not been properly initialized")
        return this._getTalentApplicationsCount(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{talentId}/positions/{positionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentPosition: IResourceMethodObservableStrict<null, null, {talentId: number, positionId: number}, ConsultantTalentPositionData> | undefined
    getTalentPosition(pathVariables: {talentId: number, positionId: number}): Promise<ConsultantTalentPositionData> {
        if (!this._getTalentPosition) throw new Error("resource has not been properly initialized")
        return this._getTalentPosition(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/proposals/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentPositionProposalCount: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<number>> | undefined
    getTalentPositionProposalCount(id: number): Promise<JsonWrappedValue<number>> {
        if (!this._getTalentPositionProposalCount) throw new Error("resource has not been properly initialized")
        return this._getTalentPositionProposalCount(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/recommendations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentRecommendationsFromConsultants: IResourceMethodObservableStrict<null, {page?: number, pageSize?: number}, {id: number}, PagedData<ConsultantTalentPositionPreviewData>> | undefined
    getTalentRecommendationsFromConsultants(id: number, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<ConsultantTalentPositionPreviewData>> {
        if (!this._getTalentRecommendationsFromConsultants) throw new Error("resource has not been properly initialized")
        return this._getTalentRecommendationsFromConsultants(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/query',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsByQuery: IResourceMethodObservableStrict<null, {q?: string, page?: number}, null, PagedData<ConsultantTalentData>> | undefined
    getTalentsByQuery(requestParams?: {q?: string, page?: number}): Promise<PagedData<ConsultantTalentData>> {
        if (!this._getTalentsByQuery) throw new Error("resource has not been properly initialized")
        return this._getTalentsByQuery(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/talents/paged',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsOfConsultant: IResourceMethodObservableStrict<ConsultantTalentFilterData, {page: number, pageSize: number}, null, PagedData<ConsultantTalentData>> | undefined
    getTalentsOfConsultant(requestBody: ConsultantTalentFilterData, requestParams: {page: number, pageSize: number}): Promise<PagedData<ConsultantTalentData>> {
        if (!this._getTalentsOfConsultant) throw new Error("resource has not been properly initialized")
        return this._getTalentsOfConsultant(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/unrated',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUnratedTalentsOfConsultant: IResourceMethodObservableStrict<null, {page?: number, pageSize?: number}, null, PagedData<ConsultantTalentData>> | undefined
    getUnratedTalentsOfConsultant(requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<ConsultantTalentData>> {
        if (!this._getUnratedTalentsOfConsultant) throw new Error("resource has not been properly initialized")
        return this._getUnratedTalentsOfConsultant(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/talents/{id}/rated',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportTalentCoachRatedTalent: IResourceMethodObservableStrict<ConsultantAssessTalentData, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    reportTalentCoachRatedTalent(requestBody: ConsultantAssessTalentData, id: number): Promise<boolean> {
        if (!this._reportTalentCoachRatedTalent) throw new Error("resource has not been properly initialized")
        return this._reportTalentCoachRatedTalent(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

}