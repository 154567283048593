import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, DisplayRecruiterData, CompanyEmploymentPositionData, RecruiterData, EmploymentPositionDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyAccountController
 */

@Injectable()
@ResourceParams({})
export class CompanyAccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/acceptPrivacyOptIn',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _acceptPrivacyOptIn: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    acceptPrivacyOptIn(): Promise<boolean> {
        if (!this._acceptPrivacyOptIn) throw new Error("resource has not been properly initialized")
        return this._acceptPrivacyOptIn(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/colleagues/{id}/activate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _activateRecruiter: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    activateRecruiter(id: number): Promise<null> {
        if (!this._activateRecruiter) throw new Error("resource has not been properly initialized")
        return this._activateRecruiter(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/colleagues',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addColleague: IResourceMethodObservableStrict<RecruiterData, null, null, DisplayRecruiterData> | undefined
    addColleague(requestBody: RecruiterData): Promise<DisplayRecruiterData> {
        if (!this._addColleague) throw new Error("resource has not been properly initialized")
        return this._addColleague(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/recruiters/{recruiterId}/positions/edit',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _assignEmploymentPositionsToRecruiter: IResourceMethodObservableStrict<null, {positionIds: number[]}, {recruiterId: number}, EmploymentPositionDisplayData[]> | undefined
    assignEmploymentPositionsToRecruiter(recruiterId: number, requestParams: {positionIds: number[]}): Promise<EmploymentPositionDisplayData[]> {
        if (!this._assignEmploymentPositionsToRecruiter) throw new Error("resource has not been properly initialized")
        return this._assignEmploymentPositionsToRecruiter(null, requestParams, {recruiterId: recruiterId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/account/profilePicture/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteProfilePicture: IResourceMethodObservableStrict<null, null, null, null> | undefined
    deleteProfilePicture(): Promise<null> {
        if (!this._deleteProfilePicture) throw new Error("resource has not been properly initialized")
        return this._deleteProfilePicture(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/colleagues/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _editColleague: IResourceMethodObservableStrict<RecruiterData, null, {id: number}, JsonWrappedValue<DisplayRecruiterData>> | undefined
    editColleague(requestBody: RecruiterData, id: number): Promise<JsonWrappedValue<DisplayRecruiterData>> {
        if (!this._editColleague) throw new Error("resource has not been properly initialized")
        return this._editColleague(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/colleagues/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getColleague: IResourceMethodObservableStrict<null, null, {id: number}, DisplayRecruiterData> | undefined
    getColleague(id: number): Promise<DisplayRecruiterData> {
        if (!this._getColleague) throw new Error("resource has not been properly initialized")
        return this._getColleague(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/colleagues',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getColleagues: IResourceMethodObservableStrict<null, {showDeleted: boolean}, null, DisplayRecruiterData[]> | undefined
    getColleagues(requestParams: {showDeleted: boolean}): Promise<DisplayRecruiterData[]> {
        if (!this._getColleagues) throw new Error("resource has not been properly initialized")
        return this._getColleagues(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnAccountInfo: IResourceMethodObservableStrict<null, null, null, DisplayRecruiterData> | undefined
    getOwnAccountInfo(): Promise<DisplayRecruiterData> {
        if (!this._getOwnAccountInfo) throw new Error("resource has not been properly initialized")
        return this._getOwnAccountInfo(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/account/profilePicture/{recruiterId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfilePicture: IResourceMethodObservableStrict<null, null, {recruiterId: number}, Blob> | undefined
    getProfilePicture(recruiterId: number): Promise<Blob> {
        if (!this._getProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getProfilePicture(null, null, {recruiterId: recruiterId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/recruiter/{id}/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecruiterPositions: IResourceMethodObservableStrict<null, null, {id: number}, CompanyEmploymentPositionData[]> | undefined
    getRecruiterPositions(id: number): Promise<CompanyEmploymentPositionData[]> {
        if (!this._getRecruiterPositions) throw new Error("resource has not been properly initialized")
        return this._getRecruiterPositions(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/colleagues',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeColleague: IResourceMethodObservableStrict<JsonWrappedValue<number>, null, null, null> | undefined
    removeColleague(requestBody: JsonWrappedValue<number>): Promise<null> {
        if (!this._removeColleague) throw new Error("resource has not been properly initialized")
        return this._removeColleague(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateOwnAccountInfo: IResourceMethodObservableStrict<RecruiterData, null, null, DisplayRecruiterData> | undefined
    updateOwnAccountInfo(requestBody: RecruiterData): Promise<DisplayRecruiterData> {
        if (!this._updateOwnAccountInfo) throw new Error("resource has not been properly initialized")
        return this._updateOwnAccountInfo(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/account/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _uploadProfilePicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, null, null> | undefined
    uploadProfilePicture(requestBody: ArrayBuffer, requestParams: {fileName: string}): Promise<null> {
        if (!this._uploadProfilePicture) throw new Error("resource has not been properly initialized")
        return this._uploadProfilePicture(requestBody, requestParams, null).toPromise()
    }

}